import React from 'react';
import Image from 'next/image';
import { LoadIcon } from '@svgs';

import styles from './styles.module.scss';

import {
  Link
} from '@components/atoms/link';

import placeholder from '@images/yepic_avatar.png';

export const Avatar = ({ founder = false }) => {
  return (
    <Link href='/profile' noStyle>
      <div className={`${styles.image}`}>
        {founder ? 
          <LoadIcon name="FoundersBadge" /> :
          <Image src={placeholder.src} height="100" width="100" alt="user avatar" /> 
        }
      </div>
    </Link>
  );
};
