import React from 'react';
// import Image from 'next/image';

import classnames from 'classnames';

import DashboardIcon from './dashboard-icon.svg';
import JobsIcon from './jobs-icon.svg';
import TimeIcon from './time-icon.svg';
import PhotosIcon from './photos-icon.svg';
import PhotosMini from './photos-mini.svg';
import BackArrow from './back-arrow.svg';
import LogoutIcon from './logout-icon.svg';
import LocationIcon from './location-icon.svg';
import FailedImage from './failed-image-icon.svg';
import RoadMapCross from './roadmap-cross.svg';
import RoadmapTick from './roadmap-tick.svg';
import Quote from './roadmap-quote.svg';
import PlusLogo from './plus-logo.svg';
import PlusIcon from './plus-icon.svg';
import FoundersBadge from './founders-badge.svg';
import Cross from '@svgs/cross.svg';
import ErrorDot from '@svgs/error-dot.svg';
import CircleArrow from '@svgs/arrow-circle.svg';
import StandardLogo from '@svgs/standard-logo.svg';
import Alert from '@svgs/alert.svg';

import { DownloadIcon } from './download-icon';
import { LookingGlass } from './lookingGlass';
import { Phone } from './phone';
import { Plus } from './plus';
import { Minus } from './minus';
import { GreenTickIllustration } from './greenTickIllustration';
import { LinkIcon } from './linkIcon';
import { ErrorDotCustom } from './errorDotCustom';

import styles from './styles.module.scss';

const Tick = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.50038 12.0009C4.10438 12.0009 3.72188 11.8449 3.43988 11.5614L0.439875 8.56136C-0.146625 7.97486 -0.146625 7.02686 0.439875 6.44036C1.02638 5.85386 1.97438 5.85386 2.56087 6.44036L4.26787 8.14736L9.25237 0.668359C9.71138 -0.0216413 10.6414 -0.207641 11.3329 0.252859C12.0214 0.713359 12.2074 1.64336 11.7484 2.33336L5.74837 11.3334C5.49938 11.7069 5.09438 11.9499 4.64888 11.9934C4.59788 11.9979 4.54988 12.0009 4.50038 12.0009Z"
        fill="#01011E"
      />
    </svg>
  );
};

const icons : {
  [key: string]: React.FunctionComponent<React.SVGAttributes<SVGElement>>
} = {
  DashboardIcon,
  JobsIcon,
  TimeIcon,
  PhotosIcon,
  PhotosMini,
  BackArrow,
  Tick,
  LogoutIcon,
  LocationIcon,
  FailedImage,
  RoadMapCross,
  RoadmapTick,
  PlusLogo,
  PlusIcon,
  FoundersBadge,
  Quote,
  Cross,
  DownloadIcon,
  ErrorDot,
  CircleArrow,
  LookingGlass,
  Phone,
  StandardLogo,
  Alert
};

interface LoadIconProps {
  name: string;
  alt?: string;
  className?: string;
  fill?: string;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
}

const LoadIcon = ({ name, alt, className, fill, size, disabled = false } : LoadIconProps) => {
  let component = null;

  switch (name) {
  case 'Tick':
    component = <Tick />;
    break;

  case 'DownloadIcon':
    component = <DownloadIcon fill={fill} />;
    break;

  case 'LookingGlass':
    component = <LookingGlass fill={fill} />;
    break;

  case 'Phone':
    component = <Phone fill={fill} />;
    break;

  case 'Plus':
    component = <Plus fill={fill} />;
    break;

  case 'Minus':
    component = <Minus fill={fill} />;
    break;

  case 'GreenTickIllustration':
    component = <GreenTickIllustration />;
    break;

  case 'LinkIcon':
    component = <LinkIcon fill={fill} />;
    break;

  case 'ErrorDotCustom':
    component = <ErrorDotCustom fill={fill} />;
    break;

  default:
    component = (
      // eslint-disable-next-line @next/next/no-img-element
      <img
        className={className}
        // @ts-ignore These types clash with css svgs - so ignored.
        height={icons[name].height}
        // @ts-ignore These types clash with css svgs - so ignored.
        width={icons[name].width}
        // @ts-ignore These types clash with css svgs - so ignored.
        src={icons[name].src}
        alt={alt}
      />
    );
  }

  return (
    <span className={classnames(size && typeof styles[size] !== 'undefined' ? styles[size] : '', `${disabled ? styles.disabled : ''}`)}>{component}</span>
  );
};

export {
  DashboardIcon,
  JobsIcon,
  TimeIcon,
  PhotosIcon,
  PhotosMini,
  BackArrow,
  Tick,
  LogoutIcon,
  LocationIcon,
  FailedImage,
  LoadIcon
};
