import React from 'react';

export const TimeIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.29289 5.29289L5.29289 2.29289C5.68342 1.90237 6.31658 1.90237 6.70711 2.29289C7.09763 2.68342 7.09763 3.31658 6.70711 3.70711L3.70711 6.70711C3.31658 7.09763 2.68342 7.09763 2.29289 6.70711C1.90237 6.31658 1.90237 5.68342 2.29289 5.29289ZM18.7071 2.29289L21.7071 5.29289C22.0976 5.68342 22.0976 6.31658 21.7071 6.70711C21.3166 7.09763 20.6834 7.09763 20.2929 6.70711L17.2929 3.70711C16.9024 3.31658 16.9024 2.68342 17.2929 2.29289C17.6834 1.90237 18.3166 1.90237 18.7071 2.29289ZM12 22C16.9706 22 21 17.9706 21 13C21 8.02944 16.9706 4 12 4C7.02944 4 3 8.02944 3 13C3 17.9706 7.02944 22 12 22ZM12 20C8.13401 20 5 16.866 5 13C5 9.13401 8.13401 6 12 6C15.866 6 19 9.13401 19 13C19 16.866 15.866 20 12 20ZM12 8C12.5523 8 13 8.44772 13 9V11.5858L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L11.2929 12.7071C11.1054 12.5196 11 12.2652 11 12V9C11 8.44772 11.4477 8 12 8Z"
        fill="#F3F3F3"
      />
    </svg>
  );
};
