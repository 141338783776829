import React from 'react';

export const DownloadIcon = ({ fill = '#17A5FD' }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 14C19.5128 14 19.9355 14.386 19.9933 14.8834L20 15V17C20 18.5977 18.7511 19.9037 17.1763 19.9949L17 20H3C1.40232 20 0.0963391 18.7511 0.00509269 17.1763L0 17V15C0 14.4477 0.447715 14 1 14C1.51284 14 1.93551 14.386 1.99327 14.8834L2 15V17C2 17.5128 2.38604 17.9355 2.88338 17.9933L3 18H17C17.5128 18 17.9355 17.614 17.9933 17.1166L18 17V15C18 14.4477 18.4477 14 19 14ZM10 0C10.5523 0 11 0.447715 11 1V10.585L12.2929 9.29289C12.6534 8.93241 13.2206 8.90468 13.6129 9.2097L13.7071 9.29289C14.0676 9.65338 14.0953 10.2206 13.7903 10.6129L13.7071 10.7071L10.7071 13.7071L10.6631 13.7485L10.5953 13.8037L10.4841 13.8753L10.3713 13.9288L10.266 13.9642L10.1175 13.9932L10 14L9.92476 13.9972L9.79927 13.9798L9.68786 13.9503L9.57678 13.9063L9.47929 13.854L9.38325 13.7872C9.35153 13.7623 9.32136 13.7356 9.29289 13.7071L6.29289 10.7071C5.90237 10.3166 5.90237 9.68342 6.29289 9.29289C6.65338 8.93241 7.22061 8.90468 7.6129 9.2097L7.70711 9.29289L9 10.585V1C9 0.447715 9.44771 0 10 0Z"
        fill={fill}
      />
    </svg>
  );
};
