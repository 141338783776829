import React from 'react';

import classNames from 'classnames';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import { BlankHeader } from '@components/atoms/blankHeader';
import { Sidebar } from '@components/organisms/sidebar';
import { Footer } from '@components/organisms/footer';

import styles from './styles.module.scss';

const Layout = ({ children, sidebar, noPadd, blankHeader = false, footer = true }) => {
  const content = <>
    {sidebar && <Sidebar />}
    <div
      className={classNames(
        `${styles.layout}`,
        `${sidebar ? styles.sidebar : ''}`,
        `${noPadd ? styles.noPadd : ''}`
      )}
    >
      <main className={styles.main}>{children}</main>
      {(footer || !sidebar) || footer && <Footer />}      
    </div>
  </>;

  if (blankHeader) {
    return <><BlankHeader>{content}</BlankHeader><ToastContainer enableMultiContainer containerId={'default'}/></>;
  }

  return <>{content}<ToastContainer enableMultiContainer containerId={'default'}/></>;
};

export { Layout };
