import React from 'react';

export const GreenTickIllustration = () => {

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 160 160">
      <ellipse
        cx={79.98}
        cy={79.76}
        rx={75.26}
        ry={75}
        style={{
          fill: '#4bbb00',
          strokeWidth: 0
        }}
      />
      <path
        d="M110.9 53.65c-1.23-.01-3.13 1.73-4.54 2.89-1.75 1.69-3.54 3.37-5.42 5.1-1.32 1.22-2.61 2.47-3.89 3.74l-9.88 9.94-7.36 7.42-3.68 3.71-1.26 1.28-.4.42-.37-.35-.93-.91-3.31-3.27-6.61-6.56-5.81-5.79L36.9 91.68l37.58 37.78 11.54-11.39 32.19-31.75 15.54-15.41-4.96-4.95c-2.68-2.67-9.06-9.91-12.48-12.13-.29-.19-.57-.32-.8-.45-2.52-1.4-4.62.27-4.62.27Z"
        style={{
          fill: '#3d8a00',
          strokeWidth: 0
        }}
      />
      <path
        d="M107.02 49.34 71.34 83.39l-16.05-16-15.58 15.53 31.63 31.53 51.26-51.08-15.58-14.03z"
        style={{
          fill: '#e0dd00',
          strokeWidth: 0
        }}
      />
      <path
        d="M20.33 119.5c1.23.91 2.47 1.85 3.7 2.73l1.37.96.95.65c1.27.87 2.57 1.71 3.87 2.52 2.61 1.62 5.3 3.13 8.05 4.45 2.75 1.32 5.59 2.46 8.48 3.29 1.44.43 2.91.74 4.38.99 1.5.23 3.06.41 4.59.62 6.14.72 12.32 1.01 18.44.54 6.11-.46 12.15-1.72 17.88-3.85l.9-.31c.3-.11.59-.25.88-.37l1.75-.76c1.17-.5 2.35-.99 3.46-1.63l3.38-1.8 3.23-2.06 1.61-1.04 1.53-1.16 3.06-2.32 2.89-2.54c.95-.86 1.95-1.66 2.82-2.61l2.7-2.75c.44-.46.91-.9 1.34-1.39l1.26-1.46c1.67-1.96 3.4-3.88 4.89-5.93a60.69 60.69 0 0 0 7.35-13.32c3.8-9.47 5.24-19.8 5.09-30.04-.09-5.13-.56-10.26-1.38-15.34-.21-1.27-.43-2.53-.68-3.8l-.39-1.89-.3-1.33-.41-1.7c-.54-3.46-2.21-8.51-2.21-8.51s14.43 13.27 18.39 33.9c3.96 20.63 2.14 31.67 2.06 33.45s-4.13 19.53-22.18 37.22c-18.06 17.69-39.82 27.25-73.55 19.96-33.73-7.29-39.18-33.39-39.18-33.39Z"
        style={{
          fill: '#3d8a00',
          strokeWidth: 0
        }}
      />
      <path
        d="M115.04 56.15c.06-.36-.32-1.78-4.13 2.46-2.62 2.91-5.32 5.64-7.98 8.42-.86.9-3.51 3.98-4.21 4.73-4.64 5.04-9.34 9.82-14.1 14.44-1.19 1.16-2.39 2.29-3.59 3.43l-1.82 1.68c-.61.53-1.21.98-1.82 1.48-2.5 1.83-5.44 3.1-8.56 3.75-1.58.33-3.57.59-5.83.59-.28 0-.57-.01-.86-.02h-.5c-.24-.02-.46-.03-.7-.05l-1.43-.15-.73-.08-.37-.04-.42-.09c-2.11-.4-4.91-1.14-7.75-1.9.06.16.16.37.14.45 0 .05.02.11 0 .14-.02 0 .01.03.05.07l.1.1.21.21c.55.56 1.11 1.12 1.67 1.68l3.43 3.46c1.88 1.9 3.78 3.83 5.65 5.74 3.48 3.55 6.88 7.02 9.83 10.04 5.39-5.23 9.47-9.18 10.77-10.44 1.38-1.34 2.84-2.85 4.26-4.29 5.41-5.46 10.92-11.27 16.38-16.91 5.73-5.75 11.44-11.46 17.1-16.89.59-.57 1.21-1.2 1.83-1.85l2.82-2.97-9.47-7.22Z"
        style={{
          fill: '#b5ac00',
          strokeWidth: 0
        }}
      />
      <path
        d="M157.5 80.55c-1.26-.95-2.7-1.06-3.61-.19-.45.43-.81 1.08-1.1 1.85-.15.39-.28.81-.39 1.25-.14.44-.26.91-.38 1.39-.41 1.92-.87 4.04-1.26 5.8a62.76 62.76 0 0 1-4.11 14l-1.05 2.42c-.76 1.64-1.48 3.3-2.36 4.89-1.77 3.36-3.75 6.62-6.03 9.67-4.54 6.1-9.97 11.58-16.19 16.02-3.1 2.22-6.36 4.25-9.8 5.95-3.43 1.7-6.99 3.14-10.65 4.29-3.65 1.17-7.43 1.95-11.23 2.49-1.9.3-3.82.41-5.74.57-1.92.05-3.85.14-5.77.05-4.14-.12-8.26-.59-12.3-1.42-4.03-.85-7.98-2.06-11.79-3.59a69.419 69.419 0 0 1-20.71-12.9c-6.04-5.45-11.18-11.88-14.98-19-3.8-7.11-6.45-14.83-7.61-22.77-1.18-7.93-1.06-16.06.46-23.91.75-3.92 1.81-7.79 3.21-11.53 1.41-3.74 3.14-7.36 5.15-10.82 8.01-13.87 20.91-24.93 36.07-30.88 7.81-2.82 16.08-4.3 24.39-4.41 8.32-.07 16.63 1.18 24.6 3.73 3.87 1.26 7.8 3.04 11.27 5.19 7.41 4.55 14.67 10.25 20.22 17.05 3.02 3.68 5.59 7.77 7.74 12 2.15 4.23 3.88 8.59 5.36 12.9.15.43.32.9.5 1.41.16.51.33 1.06.51 1.63.18.57.38 1.17.58 1.79.18.62.36 1.26.54 1.91.77 2.58 1.44 5.33 2.08 7.58.65 2.25 1.21 4.03 1.96 4.61.72.58 1.52-.02 2.39-2.64.55-1.55.8-3.23 1.06-5.01.09-.89.13-1.8.16-2.73.04-.94.03-1.89-.04-2.85-.17-3.88-1.04-7.98-2.35-12.03-2.58-8.18-7.36-15.91-12.17-22.37-9.12-12.21-21.83-21.76-36.19-27.04-17.17-6.33-36.36-6.51-53.52-.55-4.29 1.47-8.46 3.3-12.45 5.47a82.216 82.216 0 0 0-11.33 7.53c-7.12 5.63-13.34 12.46-18.23 20.19a79.037 79.037 0 0 0-11 27.71C-.46 75.08-.5 85.24 1.4 95.09a79.277 79.277 0 0 0 11.05 27.81 80.566 80.566 0 0 0 36.21 30.74 80.823 80.823 0 0 0 17.29 5.11c11.86 2.12 24.18 1.56 35.8-1.72 5.8-1.63 11.43-3.89 16.72-6.77a81.348 81.348 0 0 0 14.75-10.35c9.05-7.93 16.19-17.96 20.89-29.01 1.56-3.79 2.83-7.69 3.83-11.66.99-3.97 1.61-8.03 1.99-12.09.04-.49.07-.99.05-1.48-.07-2.36-1.26-4.18-2.49-5.13Z"
        style={{
          fill: '#01011d',
          strokeWidth: 0
        }}
      />
      <path
        d="M125.21 58.7c-3.23-3.22-4.53-4.85-6.89-7.3-2.2-2.3-4.54-4.51-6.31-5.38-.3-.15-.59-.26-.85-.32-.93-.23-1.68.03-2.27.41-.95.61-1.59 1.58-1.82 2.8-.05-1.19-.66-2.08-2.04-2.41-1.51-.36-3.34 1.24-4.73 2.26-1.66 1.57-3.37 3.13-5.19 4.72-1.27 1.12-2.5 2.28-3.7 3.48-3.09 3.13-6.18 6.26-9.28 9.39l-6.9 7.02-3.14 3.2-.74.81-.71-.65-.19-.17-.78-.77-1.56-1.54-6.43-6.37-6.37-6.35-21.57 21.41 10.85 10.91 7.85 7.94 18.91 19.13 12.11-11.89 30.41-29.81 16.01-15.84-4.67-4.66Zm-7.9 5.52-.41.44-.51.53c-1.33 1.35-2.67 2.69-4.02 4.01-2.7 2.65-5.44 5.25-8.21 7.83-5.54 5.15-11.19 10.18-16.85 15.23-3.38 3.03-6.71 6.08-9.98 9.16-1.63 1.55-3.25 3.1-4.87 4.65l-.6.59-.29.28h-.02l-.08.07c-.05.04-.09.07-.13.11l-5.18-5c-2.99-2.86-6.02-5.7-9.1-8.52-2.46-2.26-4.89-4.52-7.29-6.79-1.09-1.04-2.19-2.08-3.28-3.11-.29-.24-.59-.51-.89-.79.29-.32.58-.62.92-.97l3.9-3.87 3.94-3.84c.35-.31.64-.54.92-.76.19.24.37.47.53.68l.11.16.05.08.29.31 1.35 1.44 5.37 5.82.58.64.78.83 2.29 2.39 4.7 4.88c3.91-3.77 7.83-7.54 11.74-11.31 2.65-2.59 5.24-5.22 7.76-7.91 5.04-5.38 9.76-10.98 13.95-16.93 1.12-1.58 1.92-3.21 2.18-4.6 0 .5.06 1.02.2 1.57.98 3.94 4.95 6.71 8.22 9.6l.63.55.28.25.13.1.52.41c.36.29.73.59 1.1.92l-.76.86Z"
        style={{
          fill: '#01011d',
          strokeWidth: 0
        }}
      />
    </svg>
    
  );
};