import * as React from 'react';

import { Link } from '@components/atoms/link';
import { Container } from '@components/atoms/container';


import styles from './styles.module.scss';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <Container flexRow fullHeight={false}>
        © {new Date().getFullYear()} &middot;{' '}
        <Link where="footer" href="https://www.yepic.com">
          Yepic
        </Link>
      </Container>
    </footer>
  );
};

export { Footer };
