import React from 'react';

export const Plus = ({ fill = '#01011E'}) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M12 4C12.5523 4 13 4.44772 13 5L13 11L19 11C19.5523 11 20 11.4477 20 12C20 12.5523 19.5523 13 19 13L13 13L13 19C13 19.5523 12.5523 20 12 20C11.4477 20 11 19.5523 11 19L11 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L11 11L11 5C11 4.44772 11.4477 4 12 4Z" fill={fill}/>
    </svg>

  );
};