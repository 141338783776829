// import { event as googleAnalysticsEvent } from 'nextjs-google-analytics';
import { ampli, ButtonClickedProperties, LinkClickProperties } from '../ampli';

export const buttonClicked = (eventProperties: ButtonClickedProperties) => {
  ampli.buttonClicked(eventProperties);
  // googleAnalysticsEvent('button_clicked', { category: 'Button', ...eventProperties});
};

export const linkClicked = (eventProperties: LinkClickProperties) => {
  ampli.linkClick(eventProperties);
  // googleAnalysticsEvent('link_click', { category: 'Links', ...eventProperties});
};