import React, { useCallback, useEffect, useState } from 'react';

import { Cache } from '@aws-amplify/cache';

import Modal from 'react-modal';

import { Button } from '@components/atoms/button';

import { LoadIcon } from '@svgs';

import styles from './styles.module.scss';
import * as common from '@scss/common.module.scss';

import { useUserContext } from '@context/user';
import { useDataContext } from '@context/data';

const SignOut = ({ children, type, size, where }) => {
  const { signOut } = useUserContext();
  const { stopDataStore } = useDataContext();
  const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    Modal.setAppElement('#__next');
  }, []);

  const logout = useCallback(() => {
    (async () => {
      // Clear the image cache.
      Cache.clear();
      await stopDataStore();
      await signOut();

      window.location.replace('/login');
    })();
  }, [signOut, stopDataStore]);

  return (
    <>
      <span className={styles.button}>
        <Button
          size={size}
          onClick={() => setIsOpen(true)}
          type={type || 'primary'}
          where={`sign out modal in ${where}`}
          actionLabel="Sign out modal trigger"
        >
          <span className={styles.logoutText}>{children}</span>
          <LoadIcon alt="Log out" name={'LogoutIcon'} className={styles.logoutIcon} />
        </Button>
      </span>
      <Modal
        style={{
          content: {
            backgroundColor: styles.modalBackground,
            border: '2px solid rgba(255, 255, 255, 0.3)',
            padding: 'inherit',
            inset: '',
            overflow: 'visible',
            zIndex: 1500
          },
          overlay: {
            zIndex: 1490
          }
        }}
        overlayElement={(props, contentElement) => (
          <div {...props} className={common.overlay}>
            {contentElement}
          </div>
        )}
        contentElement={(props, children) => (
          <div {...props} className={styles.modalContentWrapper}>
            {children}
          </div>
        )}
        isOpen={modalIsOpen}
      >
        <h2 className={styles.modalTitle}>Log out</h2>
        <p className={styles.logoutText}>Are you sure you want to log out?</p>
        <Button
          where={`sign out button in ${where}`}
          actionLabel="Sign out button"
          fill
          onClick={logout}
          type={'primary'}
        >
          {children}
        </Button>
        <Button
          fill
          onClick={() => setIsOpen(false)}
          where={`Close sign out modal button in ${where}`}
          actionLabel="Close sign out modal"
          type="secondary"
        >
          Cancel
        </Button>
      </Modal>
    </>
  );
};

export { SignOut };
