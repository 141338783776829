import React, { ReactNode, useCallback } from 'react';
import { Cache } from '@aws-amplify/cache';

import { useRouter } from 'next/router';

import { Link } from '@components/atoms/link';

import { useUserContext } from '@context/user';

import { LoadIcon } from '@svgs';

import * as styles from './styles.module.scss';

const IS_PROD = process.env.IS_PROD === 'true';
const marketingSiteUrl = IS_PROD ? 'https://www.yepic.com' : 'https://dev.yepic.com';

export const BlankHeader = ({ children } : { children: ReactNode }) => {
  const { isLoggedIn } = useUserContext();
  const router = useRouter();
  
  const getLinkHref = useCallback(() => {
    if (isLoggedIn) return '/jobs';

    if (router.pathname === '/login') {
      const previouslySelectedPlan = Cache.getItem('plan_interval');

      return previouslySelectedPlan ? '/plans' : marketingSiteUrl;
    }

    return marketingSiteUrl;
  }, [isLoggedIn, router.pathname]);

  return (
    <div className={styles.wrapper}>
      <header className={styles.header}>
        {/* @ts-ignore */}
        <Link href={getLinkHref()} type="plain"><LoadIcon alt="Yepic" name="StandardLogo" /></Link>
      </header>
      {children}
    </div>
  );
};