import React from 'react';

const LogoSmall = () => {
  return (
    <svg 
      id="yepic-small-logo" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 96 72"
      width="55"
      height="44"
    >
      <path d="m60.59,0h-19.06c-.57,0-1.08.35-1.3.88-.22.53-.1,1.14.31,1.55l8.78,8.85-11.82,21.77L23.85,7.91l5.44-5.48c.4-.41.52-1.02.31-1.55-.22-.53-.73-.88-1.3-.88H1.41C.84,0,.33.35.11.88-.11,1.41,0,2.02.41,2.42l9.36,9.43,11.29,20.8-2.31,2.33c-.26.27-.41.63-.41,1s.15.74.41,1l4.33,4.36v19.03l-9.12,9.19c-.4.41-.52,1.02-.31,1.55.22.53.73.88,1.3.88h32.07c.57,0,1.08-.35,1.3-.88.22-.53.1-1.14-.31-1.55l-9.12-9.19v-24.02l13.29-24.48,9.39-9.46c.4-.41.52-1.02.31-1.55-.22-.53-.73-.88-1.3-.88Z" fill="#f3f3f3" strokeWidth="0px" />
      <path d="m95.59,35.01l-4.71-4.71c-.13-.13-.31-.21-.5-.21h-10.47v-10.47c0-.19-.08-.37-.21-.5l-4.71-4.71c-.55-.55-1.44-.55-1.98,0l-4.71,4.71c-.13.13-.21.31-.21.5v10.47h-10.47c-.19,0-.37.08-.5.21l-4.71,4.71c-.55.55-.55,1.44,0,1.98l4.71,4.71c.13.13.31.21.5.21h10.47v10.47c0,.19.08.37.21.5l4.71,4.71c.55.55,1.44.55,1.98,0l4.71-4.71c.13-.13.21-.31.21-.5v-10.47h10.47c.19,0,.37-.08.5-.21l4.71-4.71c.55-.55.55-1.44,0-1.98Z" fill="#e0dd00" strokeWidth="0px" />
    </svg>
  );
};

export { LogoSmall };
