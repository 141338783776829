import React from 'react';

import { Link } from '@components/atoms/link';
import { VisuallyHidden } from '@components/atoms/visuallyHidden';
import { LogoSmall } from '@components/atoms/logoSmall';

import { useFeatureFlagContext } from '@context/featureFlags';

import { LoadIcon } from '@svgs';

import styles from './styles.module.scss';

const Logo = () => {
  const { isBlocked } = useFeatureFlagContext();

  return (
    <Link noStyle where="logo" href={!isBlocked({path: '/jobs'}) ? '/jobs' : '/profile'}>
      <span className={styles.largeLogo}><LoadIcon name="PlusLogo" /></span>
      <span className={styles.smallLogo}><LogoSmall /></span>
      <VisuallyHidden>Go back to dashboard</VisuallyHidden>
    </Link>
  );
};

export { Logo };
