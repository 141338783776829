import React, { useState, useCallback } from 'react';
import { captureMessage, setExtra } from '@sentry/nextjs';

import useVH from 'react-vh';

import { useIntercom } from 'react-use-intercom';
import { useFeatureValue } from '@growthbook/growthbook-react';

import { Link } from '@components/atoms/link';
import { SignOut } from '@components/atoms/signOut';
import { Avatar } from '@components/atoms/avatar';
import { Logo } from '@components/molecules/logo';

import { fireToast } from '@hooks/fireToast';

import styles from './styles.module.scss';

import { useUserContext } from '@context/user';
import { checkPath } from '@utilities/featureFlagging';

import { JobIcon } from '@svgs/jobIcon';
import { TimeIcon } from '@svgs/timeIcon';
import { PhotosIcon } from '@svgs/photosIcon';
// import { DashboardIcon } from '@svgs/dashboardIcon';
import { LoadIcon } from '@svgs';

const stripeCustomersUrl = 'https://dashboard.stripe.com/customers/';

const accessRules = ({ plusMember, originalPath }) => {
  if (!plusMember) {
    return '/plans';
  }

  if (plusMember) {
    return checkPath({ path: originalPath }) ? originalPath : '/profile';
  }
};

export const Sidebar = () => {
  const [ booted, setBooted ] = useState(false);
  const { user, plusMember } = useUserContext();
  const { boot, show } = useIntercom();

  const navigationAccess = useFeatureValue('desktop--restrict-page-access', {
    jobs: true,
    time: true,
    photos: true
  });

  const { profileTrade, profileName, phoneNumber, account: { founderState, paymentPlatform, paymentPlatformCustomerId, accountTier, paymentExpiry } } = user;

  // Polyfill for vh on mobile.
  useVH();

  const openSupportChat = useCallback((e) => {
    e.preventDefault();

    if (!booted) {
      const userIntercomHash = user?.idTokenClaims?.intercomWebHash;

      if (typeof phoneNumber === 'undefined' || typeof userIntercomHash === 'undefined') {
        setExtra('phoneNumber', `${phoneNumber}`);
        setExtra('userIntercomHash', `${userIntercomHash}`);
        captureMessage('Failed to create intercom support chat', 'fatal');

        fireToast({
          type: 'error',
          message:
          'There was an issue creating your support chat. This issue has now been logged with the Yepic team.',
          id: 'IntercomSupportError'
        });
      }

      // Snake cased as suggested here: https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
      const customAttributes = {
        founder: founderState === true,
        'account tier': accountTier || 'unknown',
        'payment platform': paymentPlatform || 'unknown'
      };

      // Only add properties if they provide further detail
      if (profileName !== 'Name') customAttributes.name = profileName;
      if (profileTrade !== 'Trade') customAttributes['job description'] = profileTrade;
      if (paymentPlatform === 'STRIPE' && typeof paymentPlatformCustomerId !== 'undefined') {
        customAttributes['stripe link'] = `${stripeCustomersUrl}${paymentPlatformCustomerId}`;
      }
      if (typeof paymentExpiry !== 'undefined') customAttributes['payment expiry date'] = new Date(paymentExpiry).toISOString();

      boot({
        userId: phoneNumber,
        userHash: userIntercomHash,
        customAttributes
      });
    
      setBooted(true);
    }

    show();
  }, [booted, show, user?.idTokenClaims?.intercomWebHash, phoneNumber, founderState, accountTier, paymentPlatform, profileName, profileTrade, paymentPlatformCustomerId, paymentExpiry, boot]);

  return (
    <div className={styles.sidebar}>
      <div className={`${styles.wrapper} ${styles.logoWrapper}`}>
        <Logo size="large" />
      </div>

      <div
        className={`${styles.wrapper} ${styles.underline} ${styles.avatarWrapper}`}
      >
        <Avatar name={profileName} founder={founderState}/>
        <div className={styles.detailsWrapper}>
          <div>
            <div className={styles.details}>{profileName}</div>
            <div className={`${styles.details} ${styles.subdetails}`}>
              {profileTrade}
            </div>
            <Link where="sidebar-nav" href="/profile" type="primary">Profile</Link>
          </div>
        </div>
      </div>

      <nav>
        <ul className={styles.navList}>
          {/* <li className={styles.navListItem}>
            <Link where="sidebar-nav" href={plusMember ? '/' : '/plans'} type="nav">
              <DashboardIcon />{' '}
              <span className={styles.iconLabel}>Dashboard</span>
            </Link>
          </li> */}
          {navigationAccess.jobs && <li className={styles.navListItem}>
            <Link where="sidebar-nav" href={accessRules({plusMember, originalPath: '/jobs'})} type="nav">
              <JobIcon /> <span className={styles.iconLabel}>Jobs</span>
            </Link>
          </li>}
          {navigationAccess.time && <li className={styles.navListItem}>
            <Link where="sidebar-nav"  href={accessRules({plusMember, originalPath: '/time'})} type="nav">
              <TimeIcon /> <span className={styles.iconLabel}>Time</span>
            </Link>
          </li>}
          {navigationAccess.photos && <li className={styles.navListItem}>
            <Link where="sidebar-nav"  href={accessRules({plusMember, originalPath: '/photos'})} type="nav">
              <PhotosIcon /> <span className={styles.iconLabel}>Photos</span>
            </Link>
          </li>}
        </ul>
      </nav>

      <div className={styles.footer}>
        <nav>
          <ul className={styles.navList}>
            <li className={styles.navListItem}>
              <Link onClick={openSupportChat} where="sidebar-nav" href='/#' type='nav'>
                <LoadIcon name="Phone" /> 
                <span className={styles.iconLabel}>Support</span>
              </Link>
            </li>
            <li className={styles.navListItem}>
              <SignOut size="small" type="secondary">
                Log out
              </SignOut>
            </li>
          </ul>
        </nav>      
      </div>
    </div>
  );
};
