import React from 'react';

export const PhotosIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9949 4.82373C21.9037 3.24892 20.5977 2 19 2H6L5.88338 2.00673C5.38604 2.06449 5 2.48716 5 3C5 3.55228 5.44772 4 6 4H19L19.1166 4.00673C19.614 4.06449 20 4.48716 20 5V18L20.0067 18.1166C20.0645 18.614 20.4872 19 21 19C21.5523 19 22 18.5523 22 18V5L21.9949 4.82373ZM15 6C16.5977 6 17.9037 7.24892 17.9949 8.82373L18 9V19C18 20.5977 16.7511 21.9037 15.1763 21.9949L15 22H5C3.40232 22 2.09634 20.7511 2.00509 19.1763L2 19V9C2 7.40232 3.24892 6.09634 4.82373 6.00509L5 6H15ZM16 14.762L12.7682 18.6402C12.4187 19.0597 11.8047 19.1163 11.3859 18.7894L11.2929 18.7071L9 16.415L5.414 20H15C15.5128 20 15.9355 19.614 15.9933 19.1166L16 19V14.762ZM15 8H5C4.48716 8 4.06449 8.38604 4.00673 8.88338L4 9V18.584L8.29289 14.2929C8.65338 13.9324 9.22061 13.9047 9.6129 14.2097L9.70711 14.2929L11.932 16.517L16 11.636V9C16 8.48716 15.614 8.06449 15.1166 8.00673L15 8ZM8 10.5C8 9.67157 7.32843 9 6.5 9C5.67157 9 5 9.67157 5 10.5C5 11.3284 5.67157 12 6.5 12C7.32843 12 8 11.3284 8 10.5Z"
        fill="#F3F3F3"
      />
    </svg>
  );
};
