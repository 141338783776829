import React from 'react';

import classname from 'classnames';

import styles from './styles.module.scss';

const Container = ({
  children,
  size = 'standard',
  noPadd = false,
  colorModifier = 'black',
  fullHeight = true,
  overflowY = null,
  flexRow = false,
  centre = false
}) => {
  return (
    <section
      className={classname(
        `${styles.colorWrapper}`,
        `${styles[colorModifier]}`
      )}
    >
      <div
        className={classname(
          `${styles.container}`,
          `${styles[size] ? styles[size] : ''}`,
          `${noPadd ? styles.noPadd : ''}`,
          `${styles[colorModifier]}`,
          `${fullHeight ? styles.fullHeight : ''}`,
          `${overflowY === 'hidden' ? styles.overflowYHidden : ''}`,
          `${flexRow ? styles.flexRow : ''}`,
          `${centre ? styles.centre : ''}`
        )}
      >
        {children}
      </div>
    </section>
  );
};

export { Container };
