import React from 'react';
import { toast } from 'react-toastify';

import { LoadIcon } from '@svgs';

export const fireToast = ({
  type,
  message,
  position = 'BOTTOM_CENTER',
  id = 'fallback',
  hideProgressBar = false,
  autoClose = 5000,
  containerId = 'default',
  closeOnClick = true,
  theme
}) => {
  let theToast = null;
  switch (type) {
    
  case 'error':
    theToast = toast.error(message, {
      position: toast.POSITION[position],
      icon: <LoadIcon name="ErrorDot" size="small" height={20} width={20} />,
      toastId: id,
      autoClose,
      containerId,
      closeOnClick
    });

    break;

  case 'success':
    theToast = toast.success(message, {
      position: toast.POSITION[position],
      toastId: id,
      autoClose,
      containerId,
      closeOnClick
    });

    break;

  case 'info':
    theToast = toast.info(message, {
      position: toast.POSITION[position],
      toastId: id,
      hideProgressBar,
      autoClose,
      theme,
      containerId,
      closeOnClick
    });

    break;

  default:
    throw new Error(`Unknown toast type ${type}`);
  }

  return {
    close: () => toast.dismiss(theToast)
  };
};
