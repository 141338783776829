import React, { useCallback, useRef, ReactNode, MouseEventHandler } from 'react';
import Link from 'next/link';

import { buttonClicked } from '@utilities/analytics';

import classnames from 'classnames';

import styles from './styles.module.scss';
import linkStyles from '../link/styles.module.scss';

interface Props {
  children: ReactNode
  type: 'primary' | 'secondary' | 'tertiary';
  size?: 'small' | 'medium' | 'large';
  href?: string | undefined;
  onClick?: MouseEventHandler<HTMLAnchorElement> | Function;
  fill?: boolean;
  disabled?: boolean;
  arias?: object;
  target?: '_blank' | false;
  action?: 'submit' | 'button' | 'reset' | null;
  actionLabel?: string;
  where: string;
  testId?: string;
  linkStyle?: boolean;
  visualOnly?: boolean;
}

const Button = ({
  type = 'primary',
  size = 'medium',
  children,
  href = undefined,
  onClick = () => {},
  fill = false,
  disabled = false,
  arias,
  target = false,
  action = null,
  actionLabel,
  where,
  testId,
  linkStyle = false,
  visualOnly = false
}: Props) => {
  const dataTestIds = typeof testId === 'undefined' ? {} : { 'data-testid': testId };
  const textContent = useRef<HTMLInputElement>(null);

  const clickOverride = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, ...args: any) => {
    if (textContent.current !== null) {
      buttonClicked({
        button_name: `${textContent.current.textContent}`.trim(),
        button_location: window.location.href,
        button_type: where,
        button_target_url: href || actionLabel
      });
    }
    // @ts-ignore TS can't handle dynamic situation like passing props dynamic - so ignore.
    onClick(e, ...args);
  }, [actionLabel, href, onClick, where]);

  // Just show the button as a visual not an actual button.
  if (visualOnly) {
    return (<span
      className={classnames(
        `${styles.button} ${styles[type]} ${styles[size]} ${
          fill ? styles.fill : ''
        }
          ${disabled ? styles.disabled : ''}`
      )}
    >
      {children}
    </span>);
  }

  if (target) {
    return (
      <a
        {...arias}
        {...dataTestIds}
        disabled={disabled}
        target={target}
        href={href}
        // @ts-ignore
        ref={textContent}
        className={classnames(
          `${styles.button} ${styles[type]} ${styles[size]} ${
            fill ? styles.fill : ''
          }
          ${disabled ? styles.disabled : ''}`
        )}
        onClick={clickOverride}
      >
        {children}
      </a>
    );
  }
  if (href) {
    return (
      <Link
        {...arias}
        {...dataTestIds}
        // @ts-ignore
        ref={textContent}
        className={classnames(
          `${styles.button} ${styles[type]} ${styles[size]} ${
            fill ? styles.fill : ''
          }
          ${disabled ? styles.disabled : ''}`
        )}
        href={href}
        onClick={clickOverride}
      >
        {children}
      </Link>
    );
  }

  if (linkStyle) {
    return (
      <button
        {...arias}
        {...dataTestIds}
        // @ts-ignore
        type={action}
        disabled={disabled}
        // @ts-ignore
        ref={textContent}
        className={classnames(
          `${linkStyles.button} ${linkStyles[type]} ${linkStyles[size]} ${
            fill ? styles.fill : ''
          }
        ${disabled ? linkStyles.disabled : ''}`
        )}
        // @ts-ignore
        onClick={clickOverride}
      >
        {children}
      </button>
    );
  }

  return (
    <button
      {...arias}
      {...dataTestIds}
      // @ts-ignore
      type={action}
      disabled={disabled}
      // @ts-ignore
      ref={textContent}
      className={classnames(
        `${styles.button} ${styles[type]} ${styles[size]} ${
          fill ? styles.fill : ''
        }
        ${disabled ? styles.disabled : ''}`
      )}
      // @ts-ignore
      onClick={clickOverride}
    >
      {children}
    </button>
  );
};

interface PropsNativeButtonEvent {
  text: string;
  where: string;
  href?: string;
  actionLabel?: string;
}

const nativeButtonEvents = ({text, where, href, actionLabel}: PropsNativeButtonEvent) => {
  buttonClicked({
    button_name: `${text}`.trim(),
    button_location: window.location.href,
    button_type: where,
    button_target_url: href || actionLabel
  });
};

export { Button, nativeButtonEvents };
