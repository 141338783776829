import React from 'react';

export const JobIcon = () => {
  return (
    <svg id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M20.82,15.6l-2.15-2.15-1.06,1.06,2.19,2.19c.4,.41,.62,.97,.62,1.54,0,.58-.24,1.12-.69,1.57-.2,.2-.44,.36-.71,.46-.27,.11-.58,.15-.83,.16-.29,0-.57-.06-.83-.17-.26-.11-.5-.27-.7-.48l-2.99-2.99-1.06,1.06,2.98,2.98c.34,.34,.74,.62,1.18,.81,.44,.19,.92,.29,1.4,.29h.04c.47,0,.93-.09,1.37-.26,.45-.18,.85-.44,1.24-.83,.7-.69,1.09-1.6,1.1-2.58,0-.97-.37-1.91-1.1-2.65Z"
        fill="#f3f3f3"
      />
      <path
        d="M17.54,12.88c.98-.2,1.88-.67,2.62-1.35,.73-.68,1.27-1.54,1.54-2.49,.32-1.05,.28-2.18-.1-3.21-.09-.24-.29-.42-.54-.47-.25-.06-.51,.02-.69,.2l-2.04,2.03-1.94-1.92,2.03-2.02c.18-.18,.25-.43,.21-.67-.05-.24-.21-.45-.44-.55-.72-.31-1.51-.42-2.25-.31-1.38,.12-2.65,.75-3.58,1.78-.93,1.03-1.42,2.36-1.39,3.72,0,.04,0,.08,0,.12L6.03,2.79c-.29-.29-.77-.29-1.06,0l-2.12,2.12c-.14,.14-.22,.33-.22,.53s.08,.39,.22,.53l5.37,5.37L3.29,15.68c-.72,.64-1.14,1.52-1.2,2.48-.05,.95,.28,1.89,.9,2.59,0,0,.08,.08,.08,.09,.32,.33,.69,.6,1.11,.78,.42,.19,.87,.29,1.33,.3,.03,0,.05,0,.08,0,.43,0,.86-.08,1.26-.24,.43-.17,.82-.41,1.14-.73,.11-.1,.21-.21,.28-.3l6.97-7.83c.74,.16,1.5,.18,2.29,.06ZM4.44,5.43l1.06-1.06,4.97,4.97-1.12,.99L4.44,5.43Zm9.99,6.05l-7.29,8.19c-.05,.06-.11,.13-.18,.19-.19,.18-.41,.32-.65,.42-.25,.09-.51,.15-.77,.13-.26,0-.52-.06-.76-.17-.24-.11-.45-.25-.68-.49-.37-.41-.56-.96-.53-1.52,.03-.56,.28-1.07,.69-1.44l8.21-7.24c.24-.21,.32-.55,.2-.85-.14-.34-.21-.7-.2-1.1-.02-1,.33-1.97,1-2.71,.67-.75,1.59-1.21,2.63-1.3,.08-.01,.17-.02,.25-.02l-1.57,1.56c-.14,.14-.22,.33-.22,.53s.08,.39,.22,.53l3,2.98c.29,.29,.76,.29,1.06,0l1.55-1.55c0,.33-.06,.66-.15,.98-.2,.7-.59,1.33-1.12,1.82-.53,.49-1.19,.83-1.86,.97-.7,.1-1.4,.05-2.08-.14-.28-.08-.58,0-.77,.22Z"
        fill="#f3f3f3"
      />
    </svg>
  );
};
