import React, { ReactNode, useCallback, useRef } from 'react';

import NextLink from 'next/link';

import classNames from 'classnames';

import { linkClicked } from '@utilities/analytics';

import styles from './styles.module.scss';

interface LinkProps {
  children: ReactNode;
  href: string;
  type?: 'primary';
  noStyle?: boolean;
  where: string;
  onClick?: Function;
  openNewTab?: boolean;
}

const Link = ({ children, href, type = 'primary', noStyle = false, where, onClick = () => {}, openNewTab = false } : LinkProps) => {
  const newTabAttr = openNewTab ? { target: '_blank ', rel : 'noreferrer'} : {};
  const textContent = useRef<HTMLInputElement>(null);

  const clickOverride = useCallback((e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (textContent.current !== null && textContent.current.textContent !== null) {
      linkClicked({
        link_name: textContent.current.textContent.trim(),
        link_location: window.location.pathname,
        link_type: where,
        link_target_url: href
      });
      onClick(e);
    }
  }, [href, onClick, where]);

  if (noStyle) {
    if (href.indexOf('http:') !== -1) {
      return (
        <a href={href} onClick={clickOverride} { ...newTabAttr }>
          <span ref={textContent}>{children}</span>
        </a>
      );
    }

    return (
      <NextLink href={href} onClick={clickOverride} { ...newTabAttr }>
        <span ref={textContent}>{children}</span>
      </NextLink>
    );
  } else {
    if (href.indexOf('http:') !== -1) {
      return (
        <a href={href} className={styles[type]} onClick={clickOverride} { ...newTabAttr }>
          <span className={styles.underline} ref={textContent}>
            {children}
          </span>
        </a>
      );
    }
    return (
      <NextLink
        href={href}
        className={classNames(styles[type])}
        onClick={clickOverride}
        { ...newTabAttr }
      >
        <span className={styles.underline} ref={textContent}>
          {children}
        </span>
      </NextLink>
    );
  }
};

export { Link };
